<template>
  <b-overlay :show="showLoading" variant="dark" :opacity="0.25" rounded="sm">
    <b-card no-body class="p-3">
      <b-row>
        <b-col cols="12">
          <validation-observer ref="formRef">
            <b-form @submit.prevent>
              <b-row>

                <!-- Cashbank Account Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t(`apps.transactionsCashbank.singular.${typeIn.includes(transactionTypeCode) ? 'depositTo' : 'payFrom' }`)"
                    label-for="cashbank-account"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cashbank"
                      rules="required"
                    >
                      <v-select
                        v-if="companyActive.plan_id > 2"
                        id="cashbank-account"
                        ref="firstFocusSelectInput"
                        v-model="form.cashbankId"
                        :options="LOV.cashbankCodeAccounts"
                        :reduce="(field) => field.id"
                        label="label"
                        :state="errors.length > 0 ? false : null"
                        :disabled="actions.isPreview"
                      />
                      <v-select
                        v-else
                        id="cashbank-account"
                        ref="firstFocusSelectInput"
                        v-model="form.accountId"
                        :options="LOV.cashbankAccounts"
                        :reduce="(field) => field.id"
                        label="label"
                        :state="errors.length > 0 ? false : null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t(`apps.transactionsCashbank.singular.${typeIn.includes(transactionTypeCode) ? 'receiveFrom' : 'payTo' }`)"
                    label-for="contacts"
                  >
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="contact"
                      rules="required"
                    > -->
                    <v-select
                      id="contacts"
                      v-model="form.contactId"
                      :reduce="(field) => field.id"
                      :options="LOV.contacts"
                      @input="(field) => handleContact(field)"
                      label="label"
                      :disabled="actions.isPreview"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <!-- </validation-provider> -->
                  </b-form-group>
                </b-col>

                <!-- Tags -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('globalSingular.tags')"
                    label-for="tags"
                  >
                    <v-select
                      v-model="form.tags"
                      id="tags"
                      label="name"
                      multiple
                      :options="LOV.tags"
                      :reduce="(field) => field.id"
                      :create-option="(field) => ({ id: Date.now(), name: field })"
                      @option:created="pushTags"
                      :searchable="!actions.isPreview"
                      :selectable="(option) => !actions.isPreview"
                    />
                  </b-form-group>
                </b-col>

                <!-- Account Name Field -->
                <b-col cols="12" md="4" v-show="form.accountGeneral">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.accountName')"
                    label-for="accountName"
                  >
                    <b-form-input
                      id="accountName"
                      v-model="form.accountName"
                      placeholder="Account Name"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>

                <!-- Transaction No Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.code')"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      v-model="form.code"
                      placeholder="CI-2021020001"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>

                <!-- Office Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.office')"
                    label-for="officeId"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="officeId"
                      rules="required"
                    >
                      <v-select
                        inputId="officeId"
                        ref="firstFocusSelectInput"
                        v-model="form.officeId"
                        :options="LOV.offices"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Date Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.date')"
                    label-for="date"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date"
                      rules="required"
                    >
                      <flat-pickr
                        id="date"
                        :config="dateFormat"
                        v-model="form.date"
                        :state="errors.length > 0 ? false : null"
                        :disabled="actions.isPreview"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-table
                id="refFormTable"
                ref="refFormTable"
                fixed
                no-provider-paging
                no-provider-filtering
                :fields="tableColumns"
                :items="form.details"
                primary-key="id"
                show-empty
                class="mt-2 position-relative"
              >
                <!-- Custom Header Column -->
                <template #head(actions)>
                  <feather-icon icon="MoreVerticalIcon" class="mx-auto" />
                </template>
                <!-- Styling width -->
                <!-- Column: Account -->
                <template #cell(accountId)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="account"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <v-select
                        id="account-type"
                        v-model="form.details[index].accountId"
                        :options="LOV.accounts"
                        :reduce="(field) => field.id"
                        label="label"
                        @option:selected="
                          activityChange(
                            index,
                            LOV.accounts,
                            form.details[index].accountId
                          )
                        "
                        :state="errors.length > 0 ? false : null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Description -->
                <template #cell(remark)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <b-form-input
                        v-model="form.details[index].remark"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="
                          !actions.isPreview
                            ? $t(
                                'apps.transactionsCashbank.placeholder.description'
                              )
                            : null
                        "
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Amount -->
                <template #cell(amount)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="amount"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <cleave
                        :key="index"
                        v-model.number="form.details[index].amount"
                        :state="errors.length > 0 ? false : null"
                        class="text-right form-control"
                        :options="numeric"
                        :disabled="actions.isPreview"
                        placeholder="10,000"
                        @blur="sumAmount"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="{ index }">
                  <b-button
                    variant="flat-danger"
                    class="btn-icon invoice-edit-input"
                    :disabled="form.details.length <= 1"
                    @click="removeRow(index)"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </template>
                <!-- Costum Footer -->
                <template v-slot:custom-foot="{}">
                  <b-tr>
                    <b-td class="pl-0">
                      <b-button
                        v-if="!actions.isPreview"
                        variant="flat-primary"
                        @click="addRow"
                      >
                        <feather-icon icon="PlusIcon" />
                        {{ $t("globalActions.addMore") }}
                      </b-button>
                    </b-td>
                    <b-td class="text-right">
                      <div class="mb-0 h4 font-weight-bolder">
                        {{ $t("globalSingular.total") }}
                      </div>
                    </b-td>
                    <b-td class="text-right">
                      <div class="mb-0 h4 font-weight-bolder">
                        {{ totalAmount }}
                      </div>
                    </b-td>
                    <b-td>&nbsp;</b-td>
                  </b-tr>
                </template>
              </b-table>

              <!-- <b-card-body> -->
              <b-row>
                <!-- Description Field -->
                <b-col cols="12" md="8">
                  <b-form-group
                    :label="$t('apps.transactionsCashbank.singular.notes')"
                    label-for="notes"
                  >
                    <b-form-textarea
                      id="notes"
                      v-model="form.remark"
                      rows="1"
                      :placeholder="
                        !actions.isPreview
                          ? $t('apps.transactionsCashbank.placeholder.description')
                          : null
                      "
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- </b-card-body> -->

              <div
                class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between"
              >
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="
                    actions.isPreview ? 'outline-secondary' : 'outline-primary'
                  "
                  :to="{ name: 'apps-transactions-cashbank-list' }"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  {{ $t("globalActions.backToList") }}
                </b-button>

                <b-button
                  v-if="actions.isPreview"
                  class="mb-sm-1 mb-md-0"
                  variant="outline-danger"
                  @click="handleDelete"
                >
                  <feather-icon icon="Trash2Icon" />
                  {{ $t("globalActions.delete") }}
                </b-button>

                <div v-if="!actions.isPreview">
                  <b-button
                    v-if="!actions.isEditMode"
                    variant="outline-primary"
                    type="submit"
                    @click="handleSubmitAndCreate"
                    class="mr-1"
                  >
                    <feather-icon icon="SaveIcon" />
                    {{ `${$t("globalActions.submit")} & ${$t("globalActions.create")}` }}
                  </b-button>
                  <b-button
                    variant="primary"
                    type="submit"
                    @click="handleSubmit"
                  >
                    <feather-icon icon="SaveIcon" />
                    {{ actions.isEditMode
                      ? $t("globalActions.update")
                      : $t("globalActions.submit")
                    }}
                  </b-button>
                </div>

                <b-button
                  v-else
                  variant="outline-primary"
                  @click="changeToEdit"
                >
                  <feather-icon icon="EditIcon" />
                  {{ $t("globalActions.changeToEdit") }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BOverlay,
} from "bootstrap-vue"
import vSelect from "vue-select"
import Cleave from "vue-cleave-component"
import { ref, onMounted } from "@vue/composition-api"
import flatPickr from "vue-flatpickr-component"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required } from "@validations"
import { formatCurrency, formatDatepicker } from "@/utils/formatter"

import useFormResource from "@/comp-functions/useFormResource"
import usePushTags from "@/comp-functions/utils/usePushTags"
import useLocalization from "@/comp-functions/utils/useLocalization"
import storestate from "@/store/index"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave,
  },


  setup() {
    const {
      paramsId,
      routeParams,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
    } = useFormResource({
      url: "transaction/cashbank",
      localeContextPath: "apps.transactionsCashbank.singular.transaction",
      redirectPathName: "apps-transactions-cashbank",
    })

    const { $t } = useLocalization()

    const typeIn = ["CI", "BI"]
    const { transactionTypeCode } = routeParams
    let isBank
    let isNational = storestate.state.user.isNational
    const LOV = ref({
      cashbankAccounts: [],
      cashbankCodeAccounts: [],
      contacts: [],
      offices: [],
      accounts: [],
      accountParents: [],
      tags: [],
    })

    const showLoading = ref(false)

    const getCashbankAccount = async () => {
      LOV.value.cashbankAccounts = await get({
        url: "master/accounts/child?account_subtype_code=CASHBANK",
      })
    }

    isBank =
      transactionTypeCode === "CI" || transactionTypeCode === "CO"
        ? false
        : true
    const getCashbankCode = async () => {
      LOV.value.cashbankCodeAccounts = await get({
        url: `value/mapping-cashbank-per-users?is_bank=${isBank}&is_national=${isNational}`,
      })
    }

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: "master/accounts/activity" })
    }

    const activityChange = async (index, option, selected) => {
      option.map((field) => {
        if (field.id == selected) {
          form.value.details[index].remark = field.description
        }
      })
    }

    function removeA(arr) {
      var what,
        a = arguments,
        L = a.length,
        ax
      while (L > 1 && arr.length) {
        what = a[--L]
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1)
        }
      }
      return arr
    }

    let val = []
    const getContacts = async (field) => {
      if (field.key != "Meta" && field.key != "Backspace") {
        val.push(field.key)
      } else if (field.key == "Meta") {
        val = []
      } else {
        val = val.slice(0, val.length - 1)
      }

      removeA(val, "Shift")

      let valData = val.toString()
      let values = valData.replaceAll(",", "")

      if (values.length > 3) {
        LOV.value.contacts = await get({
          url: `value/contacts/employee/${values}`,
        })
      }
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: "value/tag" })
    }

    const getOffices = async () => {
      LOV.value.offices = await get({ url: 'master/offices'})
    }

    const clearFocus = async () => {
      val = []
    }

    const tableColumns = ref([])

    const form = ref({
      accountId: "",
      cashbankId: "",
      contactId: "",
      officeId: "",
      code: "",
      amount: "",
      date: formatDatepicker(Date.now()),
      tags: [],
      remark: "",
      accountGeneral: false,
      accountName: "",
      details: [
        // doing 'tricked' placeholder for vue-select accountId
        {
          accountId: `${$t("apps.transactionsCashbank.singular.activity")}`,
          remark: "",
          amount: null,
        },
      ],
    })
    const defaultForm = ref({})

    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: "d/m/Y",
      allowInput: true,
      disable: [
        function (date) {
          return (
            date.getTime() <
            new Date(
              companyActive.closing_year,
              companyActive.closing_month,
              1
            ).getTime()
          )
        },
      ],
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: "thousand",
    })

    const totalAmount = ref(0)
    const sumAmount = () => {
      form.value.amount = form.value.details.reduce(
        (accumulator, { amount }) => accumulator + amount,
        0
      )
      totalAmount.value = formatCurrency(form.value.amount)
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.accountId = data.account_id
      form.value.cashbankId = data.cashbank_id
      form.value.contactId = data.contact_id
      form.value.officeId = data.office_id
      form.value.code = data.code
      form.value.date = data.date
      form.value.remark = data.remark
      form.value.tags = data.tags.map((tag) => parseInt(tag))
      form.value.amount = data.amount
      totalAmount.value = formatCurrency(
        data.amount < 0 ? data.amount * -1 : data.amount
      )
      form.value.details = data.details
      form.value.details = form.value.details.map((field) => {
        field.accountId = field.activites_id
        delete field.account_id
        return field
      })
    }

    // Add row details
    const addRow = () => {
      form.value.details.push({ accountId: "", remark: "", amount: null })
    }

    // Remove row details
    const removeRow = (index) => {
      if (form.value.details.length <= 1) return false
      form.value.details.splice(index, 1)
      sumAmount()
    }

    const resetForm = () => {
      Object.keys(defaultForm.value).map((key) => {
        form.value[key] = defaultForm.value[key]
      })
      form.value.details = [
        {
          accountId: `${$t("apps.transactionsCashbank.singular.activity")}`,
          remark: "",
          amount: null,
        },
      ]
    }

    const setDefaultForm = () => {
      defaultForm.value = { ...form.value }
    }

    onMounted(() => {
      getCashbankAccount()
      getAccounts()
      getTags()
      getOffices()
      setDefaultForm()
      // getContacts()

      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }

      if (transactionTypeCode) {
        form.value.transactionTypeCode = transactionTypeCode
      }

      if (companyActive.plan_id > 2) {
        getCashbankCode()
      }

      tableColumns.value = [
        {
          key: "accountId",
          label: $t("apps.transactionsCashbank.singular.activity"),
          width: "auto",
          thClass: "bg-transparent pl-0",
          tdClass: "align-top pl-0",
        },
        {
          key: "remark",
          label: $t("apps.transactionsCashbank.singular.description"),
          width: "auto",
          thClass: "bg-transparent",
          tdClass: "align-top",
        },
        {
          key: "amount",
          label: $t("apps.transactionsCashbank.singular.amount"),
          width: "24%",
          thClass: "bg-transparent text-right width-25-per",
          tdClass: "align-top text-right",
        },
        {
          key: "actions",
          width: "12%",
          thClass: "bg-transparent text-right width-10-per  pr-0",
          tdClass: "align-top text-right pr-0",
        },
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async (newTag) => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex((val) => val === newTag.id)] = id
    }

    return {
      companyActive,
      pushTags,
      typeIn,
      required,
      LOV,
      showLoading,
      tableColumns,
      form,
      defaultForm,
      resetForm,
      addRow,
      removeRow,
      dateFormat,
      activityChange,
      totalAmount,
      sumAmount,
      numeric,
      paramsId,
      transactionTypeCode,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      isNational,
      getContacts,
      clearFocus,
    }
  },


  methods: {
    handleDelete() {
      this.destroy(this.$swal)
    },

    handleContact(item) {
      let dataContact = this.LOV.contacts.filter((field) => field.id === item)
      this.form.accountGeneral =
        dataContact[0].is_general === true ? true : false
      this.form.accountName = ""
    },

    async handleSubmit (useRedirect = true) {
      this.showLoading = true
      this.form.details = this.form.details.map(field => {
        field.amount = !this.typeIn.includes(this.transactionTypeCode) ? field.amount * 1 : field.amount * -1
        return field
      })

      await this.store({
        $swal: this.$swal,
        data: this.form,
        useRedirect: useRedirect,
        onCancelCallback: () => {
          this.isCanceled = true
        }
      })
        .catch((error) => {
          console.log(`Request error, ${error.message || error}`)
        })
        .finally(() => {
          this.showLoading = false
        })
    },

    async handleSubmitAndCreate() {
      await this.handleSubmit(false)

      if (!this.isCanceled) {
        await this.resetForm()
        await this.formRef.reset()
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
